import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

// data
import { contact } from "../data/data.json"

//  components
import Form from "../components/contact/Form"
import LetsTalk from "../components/contact/LetsTalk"

//  styled-components
const Wrapper = styled.div`
  /* overflow: hidden; */
  /* overflow: visible; */

  color: #022439;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
`

const Left = styled.div`
  /* min-width: 400px; */
  display: grid;
  grid-template-rows: max-content auto 108px;
`
const Right = styled.div``

const ContactItems = styled.div`
  margin-top: 15px;
`

const ContactItem = styled.div`
  min-height: 150px;
  padding-bottom: 10px;

  & > div:nth-child(1) {
    font-size: 18px;
  }

  & > div:nth-child(2) {
    padding-left: 10px;
    font-size: 25px;
    font-weight: 700;
    color: #3c5870;
  }
`

const Greeting = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & > p {
    margin: 0px;
    margin-bottom: 4px;
    font-size: 18px;
  }
`

const Hr = styled.hr`
  border: none;
  height: 2px;
  width: 60%;
  margin-left: 0;
  margin-top: 20px;
  background: sandybrown;
`

const AltMessage = styled.div``

const Message = styled.div`
  display: grid;
  grid-template-columns: max-content auto;

  & > div:nth-child(2) {
    min-width: 150px;
    position: relative;
  }
`

const Line = styled.div`
  position: absolute;
  top: 53%;
  right: 10px;

  width: 70%;
  height: 2px;
  background: sandybrown;
  display: inline-block;
`

class Contact extends Component {
  state = {}
  render() {
    return (
      <Wrapper>
        <Left>
          <ContactItems>
            {contact.map((item, i) => (
              <ContactItem key={i}>
                <div>{item.title}</div>
                <div>{item.content}</div>
              </ContactItem>
            ))}
            <Hr />
          </ContactItems>

          <Greeting>
            <p>If you have any questions about my work,</p>
            <p>Or a project in mind,</p>
            <p>Or maybe just want to say hello...</p>
          </Greeting>

          {this.props.hideForm ? (
            <AltMessage>
              <div>
                <LetsTalk message="Thanks for reaching out!" friction={1 / 32} />
              </div>
            </AltMessage>
          ) : (
            <Message>
              <div>
                <LetsTalk message="Lets Talk!" friction={1 / 16} />
              </div>
              <div>
                <Line />
              </div>
            </Message>
          )}
        </Left>

        {this.props.hideForm ? null : (
          <Right>
            <Form />
          </Right>
        )}
      </Wrapper>
    )
  }
}

const mapStateToProps = state => ({
  hideForm: state.hideForm
})

export default connect(mapStateToProps)(Contact)
