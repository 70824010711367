import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

//  hoc wrapper
import NavigationContainer from '../../containers/navigation/NavigationContainer'

//  styled-components
const NavLink = styled(Link)`
  text-decoration: none;
  color: #3c5870;
  letter-spacing: 2px;

  transition: all 0.5s;
`

const Div = styled.div`
  text-align: center;
  cursor: pointer;
  position: fixed;
  z-index: 20;

  font-size: 22px;
  font-weight: 600;

  color: #3c5870;
  background-color: rgba(247, 243, 240, 0.4);

  text-decoration: ${props => (props.isActive ? 'line-through' : 'none')};
  transition: all 0.5s;

  &:hover {
    background-color: #3c5870;
  }

  &:hover ${NavLink} {
    color: rgb(247, 243, 240);
  }

  transition: all 0.3s;
  ${props =>
    props.hide
      ? 'opacity: 0; visibility: hidden;'
      : 'opacity: 1; visibility: visible;'}
`

const Right = styled(Div)`
  margin: 20px;
  padding: 3px 10px;
  top: 50%;
  right: 0;
  transform: ${props =>
    props.isActive
      ? 'rotate(-90deg) skewX(-10deg)'
      : 'rotate(-90deg) skewX(-10deg)'};

  &:hover {
    transform: skewX(-10deg);
  }
`

const Left = styled(Div)`
  margin: 20px;
  padding: 3px 10px;
  top: 50%;
  left: 0;
  transform: ${props =>
    props.isActive
      ? 'rotate(90deg) skewX(-10deg)'
      : 'rotate(90deg) skewX(-10deg)'};

  &:hover {
    transform: skewX(-10deg);
  }
`

const Bottom = styled(Div)`
  margin-bottom: 20px;
  padding: 3px 10px;
  bottom: 0;
  left: 50%;

  transform: translate(-50%, 0) skewX(-10deg);

  &:hover {
    /* transform: translate(-50%, 0) skewX(-10deg); */
  }
`

const Line = styled.span`
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  background: black;
`

const ControllerOuter = styled.div`
  text-align: center;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  margin: 20px;
  z-index: 10;

  width: 45px;
  height: 45px;

  /*  background-color: rgba(255, 255, 255, 0.6); */

  /* transform: skewX(-10deg); */

  & span {
    background: #3c5870;
    transition: all 0.3s;
  }

  & div {
    position: relative;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
  }

  & span:nth-child(1) {
    ${props =>
      !props.hide
        ? `transform: rotate(225deg);`
        : `transform: none;
          margin-bottom: 5px;`}
  }

  & span:nth-child(2) {
    ${props =>
      !props.hide
        ? `transform: rotate(-225deg);`
        : `transform: none;
          margin-top: 5px;`}
  }
`

//  components
const NavRight = ({ title, link, hide, currentPage, handleClick }) => {
  const isActive = currentPage === link ? true : false
  return (
    <Right hide={hide} isActive={isActive}>
      <NavLink to={link} onClick={handleClick}>
        {title}
      </NavLink>
    </Right>
  )
}

const NavBottom = ({ title, link, hide, currentPage, handleClick }) => {
  const isActive = currentPage === link ? true : false
  return (
    <Bottom hide={hide} isActive={isActive}>
      <NavLink to={link} onClick={handleClick}>
        {title}
      </NavLink>
    </Bottom>
  )
}

const NavLeft = ({ title, link, hide, currentPage, handleClick }) => {
  const isActive = currentPage === link ? true : false
  return (
    <Left hide={hide} isActive={isActive}>
      <NavLink to={link} onClick={handleClick}>
        {title}
      </NavLink>
    </Left>
  )
}

const Controller = ({ hide, handleClick }) => (
  <ControllerOuter hide={hide} onClick={handleClick}>
    <div>
      <Line />
      <Line />
    </div>
  </ControllerOuter>
)

const NavItemRight = NavigationContainer(NavRight)
const NavItemLeft = NavigationContainer(NavLeft)
const NavItemBottom = NavigationContainer(NavBottom)
const NavController = NavigationContainer(Controller)

export { NavItemRight, NavItemLeft, NavItemBottom, NavController }
