import React, { Component } from "react"
import { connect } from "react-redux"

//  data
import { quotes } from "../../data/data.json"

//  actions
import { togglePreloader, preloaderComplete, toggleNavigation } from "../../actions/actions"

const Wrapper = WrappedComponent => {
  class PreloaderContainer extends Component {
    animationTime = 5
    state = {
      hide: false
    }

    handleClick = () => {
      // window.scrollTo(0, 0)
      this.setState({ hide: true })
      setTimeout(() => {
        this.props.togglePreloader()
      }, 0.5 * 1000)
    }

    render() {
      const quote = quotes[Math.floor(Math.random() * quotes.length)]
      return (
        <React.Fragment>
          {this.props.unmountPreloader ? null : (
            <WrappedComponent
              handleClick={this.handleClick}
              {...this.props}
              quote={quote}
              hide={this.state.hide}
              animationTime={this.animationTime}
            />
          )}
        </React.Fragment>
      )
    }

    componentDidMount = () => {
      setTimeout(() => {
        // this.props.showNav()
        this.props.preloaderComplete()
      }, (this.animationTime + 0.5) * 1000)
    }

    componentWillUnmount = () => {
      this.props.togglePreloader()
    }
  }

  const mapStateToProps = state => {
    return {
      hasLoaded: state.hasLoaded,
      unmountPreloader: state.unmountPreloader,
      hasPreloaderCompleted: state.hasPreloaderCompleted
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      showNav: () => {
        dispatch(toggleNavigation(false))
      },
      preloaderComplete: () => {
        dispatch(preloaderComplete())
      },
      togglePreloader: () => {
        dispatch(togglePreloader())
      }
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PreloaderContainer)
}

export default Wrapper
