import React from "react"
import styled from "styled-components"

//  hoc wrapper
import DoubleTickerContainer from "../../containers/portfolio/DoubleTickerContainer"

//  styled-components
const PreWrapper = styled.div`
  position: absolute;

  right: 0;
  top: 15%;

  /* transform: translate(-50%, 0); */
`

const Line = styled.div`
  text-transform: uppercase;
  font-size: 100px;
  line-height: 83%;
  font-weight: 700;
  width: 680px;
`

const Wrapper = styled(PreWrapper)`
  & > ${Line}:nth-child(1) {
    text-align: center;
  }
  & > ${Line}:nth-child(2) {
    text-align: right;
  }
  & > ${Line}:nth-child(3) {
    text-align: right;
    padding-right: 10%;
  }
`

const TitleTicker = ({ ticker }) => {
  return (
    <Wrapper>
      <Line>portfolio </Line>
      <Line>from</Line>
      <Line>{ticker[0]} to</Line>
      <Line>{ticker[1]}</Line>
    </Wrapper>
  )
}

export default DoubleTickerContainer(TitleTicker)
