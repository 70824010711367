import React, { Component } from "react"
import { connect } from "react-redux"

//  actions
import { toggleNavigation } from "../../actions/actions"

const NavigationContainer = WrappedComponent => {
  class Wrapper extends Component {
    handleClick = () => {
      this.props.toggleNavigation(!this.props.hide)
    }

    render() {
      return <WrappedComponent handleClick={this.handleClick} {...this.props} />
    }
  }

  const mapStateToProps = state => ({
    hide: state.navigation.hide
  })

  const mapDispatchToProps = dispatch => ({
    toggleNavigation: mode => {
      dispatch(toggleNavigation(mode))
    }
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Wrapper)
}

export default NavigationContainer
