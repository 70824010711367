import React from "react"
import styled from "styled-components"

//  hoc wrapper
import TripleTickerContainer from "../../containers/about_me/TripleTickerContainer"

//  styled-components
const Wrapper = styled.div`
  font-size: 17px;
  letter-spacing: 2px;
`

const Ticker = ({ ticker }) => {
  return (
    <Wrapper>
      <span>{ticker[0]} / </span>
      <span>{ticker[1]} / </span>
      <span>{ticker[2]}</span>
    </Wrapper>
  )
}

export default TripleTickerContainer(Ticker)
