import React, { Component } from 'react'

//  containers & components
import PortfolioTitle from '../components/portfolio/PortfolioTitle'
import FilterSecondary from '../components/portfolio/FilterSecondary'
import PortfolioNaviation from '../components/portfolio/NavWidget'
import PortfolioContent from '../components/portfolio/PortfolioContent'
import Preloader from '../components/preloader/Preloader'

class PortfolioPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Preloader />
        <FilterSecondary />
        <PortfolioNaviation {...this.props} />
        <PortfolioTitle />
        <PortfolioContent />
      </React.Fragment>
    )
  }
}

export default PortfolioPage
