import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//  styled-components
const Line = styled.div`
  height: ${props => (props.loaded ? "15%" : "0")};
  width: 2px;
  background: black;

  position: fixed;
  left: 50%;
  bottom: 25%;

  transition: all 0.5s ease-in-out;
  transform: translate(-50%, 0);
`

const Button = styled.div`
  position: fixed;
  bottom: 12%;
  left: 50%;
  width: max-content;
  padding: 3px 10px;

  cursor: pointer;

  color: black;
  text-decoration: none;
  font-size: 30px;
  font-weight: 600px;

  transition: all 0.5s ease-in-out;
  visibility: ${props => (props.loaded ? "visible" : "hidden")};
  opacity: ${props => (props.loaded ? "1" : "0")};
  transform: ${props =>
    props.loaded ? "translate(-50%, -50%)" : "translate(-50%, +150%)skew(-10deg)"};

  &:hover {
    transition: all 0.2s;
    background: lightgrey;
    transform: ${props =>
      props.loaded ? "translate(-50%, -50%) skew(-10deg)" : "translate(-50%, +150%)"};
  }
`

const Explore = ({ loaded, handleClick }) => {
  return (
    <React.Fragment>
      <Line loaded={loaded} />
      <Button loaded={loaded} onClick={handleClick}>
        Explore...
      </Button>
    </React.Fragment>
  )
}

Explore.propTypes = {
  loaded: PropTypes.bool
}

export default Explore
