import React, { Component } from "react"
import { connect } from "react-redux"

const PortfolioNavContainer = WrappedComponent => {
  class Wrapper extends Component {
    state = {
      position: { percentage: 0, absolute: 0 }
    }
    getPosition = () => {
      const parent = document.body.parentNode
      const position =
        ((document.body.scrollTop || parent.scrollTop) /
          (parent.scrollHeight - parent.clientHeight)) *
        100
      const percentage = Math.round(position)
      const result = { percentage: percentage, absolute: parent.scrollTop }

      // this.props.updatePosition({ position: result })
      this.setState({ position: result })
    }

    componentDidMount = async () => {
      window.addEventListener("scroll", this.getPosition)
    }

    componentWillUnmount = () => {
      window.removeEventListener("scroll", this.getPosition)
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />
    }
  }

  const mapStateToProps = state => ({
    portfolio: state.portfolioFiltered,
    activeItem: state.activeItem
  })

  return connect(mapStateToProps)(Wrapper)
}

export default PortfolioNavContainer
