import React from 'react'
import WayPoint from 'react-waypoint'
import styled from 'styled-components'

//  hoc wrapper
import PortfolioContainer from '../../containers/portfolio/PortfolioContainer'

//  styled-components
const PortfolioItem = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;

  scroll-snap-align: start;
  

  /* padding: 3vh 3vw; */
  /* background: ${props => `url(${props.photo})`};
  background-size: cover; */
`

const Photo = styled.div`
  position: absolute;
  top: 3vh;
  left: 2vw;
  height: 94vh;
  width: 96vw;
  overflow: hidden;

  & > img {
    /* height: 100%;
    width: 100%; */
    /* object-fit: cover; */

    max-width: 100%;
    height: auto;
  }
`

const NDFilter = styled.div`
  position: absolute;
  top: 3vh;
  left: 2vw;
  height: 94vh;
  width: 96vw;
  /* width: 100%;
  height: 100%; */
  background: black;
  border-radius: 3px;
  opacity: 0.7;
`

const ScrollContainer = styled.div`
  width: 10%;
  height: 10%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PortfolioContent = ({ onLoad, portfolio, updateActiveItem }) => {
  return (
    <React.Fragment>
      {portfolio.map((el, i) => (
        <PortfolioItem key={i}>
          <Photo>
            <img
              src={require('../../assets/media/portfolio/' + el.cover_photo)}
              alt="portfolio-images"
              onLoad={onLoad}
            />
          </Photo>
          <NDFilter>
            <ScrollContainer>
              <WayPoint onEnter={() => updateActiveItem(el.project_name)}>
                <div />
              </WayPoint>
            </ScrollContainer>
          </NDFilter>
        </PortfolioItem>
      ))}
    </React.Fragment>
  )
}

export default PortfolioContainer(PortfolioContent)
