import React, { Component } from "react"
import { connect } from "react-redux"
import { portfolioLoaded } from "../../actions/actions"
// import http from "../../services/httpService"

//  actions
import { updateActivePortfolioItem } from "../../actions/actions"

const Wrapper = WrappedComponent => {
  class PortfolioContainer extends Component {
    state = { imagesLoaded: 0 }

    onLoad = () => {
      let { imagesLoaded } = this.state
      imagesLoaded += 1
      if (imagesLoaded === this.props.n) {
        this.props.portfolioLoaded()
        window.scrollTo(0, 0)
        console.log("portfolio loaded")
      } else {
        this.setState({ imagesLoaded: imagesLoaded })
      }
    }

    render() {
      return <WrappedComponent onLoad={this.onLoad} {...this.props} {...this.state} />
    }
  }

  const mapStateToProps = state => ({
    n: state.portfolio.length,
    portfolio: state.portfolioFiltered
  })

  const mapDispatchToProps = dispatch => ({
    updateActiveItem: activeItem => {
      dispatch(updateActivePortfolioItem(activeItem))
    },
    portfolioLoaded: () => {
      dispatch(portfolioLoaded())
    }
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PortfolioContainer)
}

export default Wrapper
