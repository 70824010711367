import React, { Component } from 'react'
import { connect } from 'react-redux'
import http from '../../services/http'
import Joi from 'joi'

//  actions
import { hideForm } from '../../actions/actions'

const Wrapper = WrappedComponent => {
  class ContactFormContainer extends Component {
    state = {
      form: { name: '', email: '', message: '' },
      errors: { name: true, email: true, message: true },
      showErrors: false
    }

    handleChange = ({ currentTarget: input }) => {
      const { form } = this.state

      //  updates the changed value in the form
      form[input.name] = input.value

      //  runs validation and updates the errors
      const errors = this.validate(this.state.form, this.validationSchema)

      //  updates state
      this.setState({ form: form, errors: errors })
    }

    handleClick = async e => {
      e.preventDefault()
      if (this.state.errors === null) {
        const { data } = await http.post('/contact', { form: this.state.form })
        console.log('Form submitted...')

        if(data === true) { this.props.formSubmitted() }
      } else {
        this.setState({ showErrors: true })
      }
    }

    render() {
      return (
        <React.Fragment>
          {this.props.hideForm ? null : (
            <WrappedComponent
              {...this.state}
              hideForm={this.props.hideForm}
              handleChange={this.handleChange}
              handleClick={this.handleClick}
            />
          )}
        </React.Fragment>
      )
    }

    validationSchema = Joi.object().keys({
      name: Joi.string()
        .min(3)
        .max(20)
        .required()
        .label("Don't forget your name"),
      email: Joi.string()
        .email({ minDomainAtoms: 2 })
        .label('Please enter a correct email'),
      message: Joi.string()
        .min(6)
        .required()
        .label('Could you elaborate a bit more...')
    })

    validate = (data, schema) => {
      const result = Joi.validate(data, schema, { abortEarly: false })
      if (!result.error) {
        this.setState({ showErrors: false })
        return null
      }

      const errors = { name: false, email: false, message: false }

      for (let item of result.error.details) {
        errors[item.path[0]] = item.context.label
      }
      return errors
    }
  }

  const mapDispatchToProps = dispatch => ({
    formSubmitted: () => {
      dispatch(hideForm())
    }
  })

  return connect(
    null,
    mapDispatchToProps
  )(ContactFormContainer)
}

export default Wrapper
