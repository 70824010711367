import data from '../data/data.json'
import portfolio from '../data/portfolio_array.json'

function StateInit() {
  this.hasLoaded = false
  this.hasPreloaderCompleted = false
  this.unmountPreloader = false
  this.navigation = { hide: true }
  this.hideForm = false
  this.portfolio = portfolio
  this.portfolioFiltered = portfolio
  this.activeItem = ''
  this.filters = data.portfolio.filters
  this.activeFilter = 'all'
  this.hideSecondaryFilter = true
}

const rootReducer = (state = new StateInit(), action) => {
  switch (action.type) {
    case 'PORTFOLIO_LOADED':
      return {
        ...state,
        hasLoaded: true
      }
    case 'PRELOADER_COMPLETE':
      return {
        ...state,
        hasPreloaderCompleted: true
      }
    case 'HIDE_PRELOADER':
      return {
        ...state,
        unmountPreloader: true
      }
    case 'HIDE_CONTACT_FORM':
      return {
        ...state,
        hideForm: true
      }
    case 'APPLY_FILTER':
      return {
        ...state,
        activeFilter: action.filter,
        portfolioFiltered:
          action.filter === 'all'
            ? portfolio
            : portfolio.filter(el => el.filters.includes(action.filter))
      }
    case 'TOGGLE_NAVIGATION':
      return {
        ...state,
        navigation: { hide: action.mode }
      }
    case 'TOGGLE_SECONDARY_FILTER':
      return {
        ...state,
        hideSecondaryFilter: action.mode
      }
    case 'UPDATE_ACTIVE_PORTFOLIO_ITEM':
      return {
        ...state,
        activeItem: action.activeItem
      }
    default:
      return state
  }
}

export default rootReducer
