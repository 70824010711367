import React, { Component } from "react"

const DoubleTickerContainer = WrappedComponent => {
  class Wrapper extends Component {
    state = {
      ticker: [this.props.ticker[0][0], this.props.ticker[1][0]]
    }
    _isMounted = false
    _includeUppercase = false

    incrementLetter = (word, index, uppercase = true) => {
      const charInputCode = word.charCodeAt(index)
      let charOutput = ""

      if (uppercase) {
        switch (charInputCode) {
          case 32: //  space
            charOutput = "1"
            break
          case 57: //  '9'
            charOutput = "a"
            break
          case 122: //  lower z
            charOutput = "A"
            break
          case 90: //  upper z
            charOutput = " "
            break
          default:
            charOutput = String.fromCharCode(charInputCode + 1)
            break
        }
      } else {
        switch (charInputCode) {
          case 32: //  space
            charOutput = "0"
            break
          case 57: //  '9'
            charOutput = "a"
            break
          case 122: //  lower z
            charOutput = " "
            break
          // case 90: //  upper z
          //   charOutput = " "
          //   break
          default:
            charOutput = String.fromCharCode(charInputCode + 1)
            break
        }
      }

      let output = word.substr(0, index) + charOutput + word.substr(index + 1)
      return output
    }

    flipWord = (word1, word2, callback) => {
      return new Promise(resolve => {
        // let Obj = {}
        const flipTime = 0.002
        let inputWord = word1
        const outputWord = word2
        const stringLength = Math.max(inputWord.length, outputWord.length)
        let i = 0

        const letterFlipper = setInterval(() => {
          //  Checks to see if the words match
          if (inputWord === outputWord) {
            clearInterval(letterFlipper)
            resolve()
          }

          //  Checks to see if the characters match
          if (inputWord.charAt(i) === outputWord.charAt(i)) {
            i = i + 1
          }

          //  Executes if the output word is longer than the input
          if (outputWord.length > inputWord.length) {
            if (i >= inputWord.length && i < stringLength) {
              // If we're at the end of our input string
              // we add on a character to size up to the
              // output string size
              inputWord = inputWord + "a"
            }
          }

          //  Executes if the output word is shorter than the input
          else if (inputWord.length > outputWord.length) {
            if (i === outputWord.length - 1 && i < stringLength) {
              inputWord = inputWord.slice(0, -1)
            }
          }

          //  We increment the letter once
          inputWord = this.incrementLetter(inputWord, i, this._includeUppercase)
          // console.log(`i: ${i}, char: ${inputWord[i]}, output: ${outputWord[i]}, ${this.state.tick}`)
          // this.setState({ tick: inputWord })
          // return inputWord
          callback(inputWord)
        }, flipTime * 1000)
      })
    }

    flipWordsInLine = (wordsCollection, callback) => {
      return new Promise(async resolve => {
        let outputArray = wordsCollection.map(el => el[0])

        for (let a = 0; a < wordsCollection.length; a++) {
          await this.flipWord(wordsCollection[a][0], wordsCollection[a][1], outputWord => {
            outputArray[a] = outputWord
            callback(outputArray)
          })
        }
        resolve()
      })
    }

    flipper = async (wordsCollection, index, pause) => {
      const indexMax = wordsCollection[0].length - 1

      const flipWords = wordsCollection.map(array => [
        array[index],
        array[index === indexMax ? 0 : index + 1]
      ])

      await this.flipWordsInLine(flipWords, outputArray => {
        if (!this._isMounted) return

        this.setState({ ticker: outputArray })
      })

      const updatedIndex = index === indexMax ? 0 : index + 1

      new Promise(resolve =>
        setTimeout(
          () => resolve(this._isMounted && this.flipper(wordsCollection, updatedIndex, pause)),
          pause * 1000
        )
      )
    }

    componentDidMount = async () => {
      this._isMounted = true
      setTimeout(() => this.flipper(this.props.ticker, 0, 3), 3 * 1000)
    }

    componentWillUnmount = () => {
      this._isMounted = false
    }

    render() {
      return <WrappedComponent ticker={this.state.ticker} />
    }
  }

  return Wrapper
}

export default DoubleTickerContainer
