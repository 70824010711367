import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"

import { colours } from "../../assets/themes/main.json"

//  variables
const width = "400px"

//  styled-components
const PreWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`

const QuoteContainer = styled.div`
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
`

const Redaction = styled.div`
  position: absolute;
  width: 100%;

  & > div {
    height: 65px;
    display: inline-block;
  }
`
const Full = styled.div`
  background: ${colours.cream};
  /* width: 400px; */
  width: 0px;
`

const Empty = styled.div`
  /* width: 380px; */
`

const Content = styled.div``

const Block = styled.div`
  width: ${width};

  margin: 5px 0;

  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  /* font-family: "Abril Fatface", cursive; */
`

const Left = styled(Block)`
  & > ${Content} {
    text-align: left;
    display: grid;
    grid-template-columns: max-content auto;
  }

  & ${Full} {
    float: right;
  }
`

const Right = styled(Block)`
  & > ${Content} {
    text-align: right;
    display: grid;
    grid-template-columns: auto max-content;
  }

  & ${Full} {
    float: left;
  }
`

const Text = styled.div`
  margin: 0px 5px;
`

const Space = styled.div`
  border: 8px black solid;
`

//  Animation

// Create the keyframes

const driftIn1 = keyframes`
  0% {
    transform: translate(+100vw, 0);
  }
  15% {
    transform: translate(0, 0);
  }
`

const driftIn2 = keyframes`
  0% {
    transform: translate(-100vw, 0);
  }
  15% {
    transform: translate(-100vw, 0);
  }
  30% {
    transform: translate(0, 0);
  }
`

const driftIn3 = keyframes`
 0% {
    transform: translate(+100vw, 0);
  }
  30% {
    transform: translate(+100vw, 0);
  }
  45% {
    transform: translate(0, 0);
  }
`

const driftIn4 = keyframes`
 0% {
    transform: translate(-100vw, 0);
  }
  45% {
    transform: translate(-100vw, 0);
  }
  60% {
    transform: translate(0, 0);
  }
`

const unredact1 = keyframes`
  0% {
    width: ${width};
  }
  15% {
    width: ${width};
  }
  30% {
    width: 0px;
  }
`

const unredact2 = keyframes`
  0% {
    width: ${width};
  }
  30% {
    width: ${width};
  }
  45% {
    width: 0px;
  }
`

const unredact3 = keyframes`
  0% {
    width: ${width};
  }
  45% {
    width: ${width};
  }
  60% {
    width: 0px;
  }
`

const unredact4 = keyframes`
  0% {
    width: ${width};
  }
  60% {
    width: ${width};
  }
  75% {
    width: 0px;
  }
`

const hideBackground = keyframes`
  0% {
    background: black;
    color: ${colours.cream};
  }
  85% {
    background: black;
    color: ${colours.cream};
  }
  100% {
    background: none;
    color: black;
  }
`

const showBorder = keyframes`
  0% {
    border: none;
    background: ${colours.cream};
  }
  85% {
    border: none;
    background: ${colours.cream};
  }
  100% {
    border: 8px black solid;
    background: none;
  }
`

const Wrapper = styled(PreWrapper)`
  animation: ${hideBackground} ${props => props.time}s ease-in-out;

  & ${QuoteContainer} {
    transition: all 0.8s;
    transform: ${props => (props.loaded ? "translate(-50%, -90%);" : "translate(-50%, -50%);")};
  }

  & ${QuoteContainer} > div:nth-child(1) {
    animation: ${driftIn1} ${props => props.time}s ease-in-out;

    & ${Space} {
      animation: ${showBorder} ${props => props.time}s ease-in-out;
    }

    & ${Full} {
      animation: ${unredact1} ${props => props.time}s ease-in-out;
    }
  }
  & ${QuoteContainer} > div:nth-child(2) {
    animation: ${driftIn2} ${props => props.time}s ease-in-out;

    & ${Space} {
      animation: ${showBorder} ${props => props.time}s ease-in-out;
    }

    & ${Full} {
      animation: ${unredact2} ${props => props.time}s ease-in-out;
    }
  }
  & ${QuoteContainer} > div:nth-child(3) {
    animation: ${driftIn3} ${props => props.time}s ease-in-out;

    & ${Space} {
      animation: ${showBorder} ${props => props.time}s ease-in-out;
    }

    & ${Full} {
      animation: ${unredact3} ${props => props.time}s ease-in-out;
    }
  }
  & ${QuoteContainer} > div:nth-child(4) {
    animation: ${driftIn4} ${props => props.time}s ease-in-out;

    & ${Space} {
      animation: ${showBorder} ${props => props.time}s ease-in-out;
    }

    & ${Full} {
      animation: ${unredact4} ${props => props.time}s ease-in-out;
    }
  }
`

const Quote = ({ quote, loaded, animationTime }) => {
  return (
    <Wrapper time={animationTime} loaded={loaded}>
      <QuoteContainer>
        {quote.map((el, i) =>
          i % 2 === 0 || i === 0 ? (
            <Left key={i}>
              <Content>
                <Redaction>
                  <Full />
                  <Empty />
                </Redaction>
                <Text>{el}</Text>
                <Space />
              </Content>
            </Left>
          ) : (
            <Right key={i}>
              <Content>
                <Redaction>
                  <Empty />
                  <Full />
                </Redaction>
                <Space />
                <Text>{el}</Text>
              </Content>
            </Right>
          )
        )}
      </QuoteContainer>
    </Wrapper>
  )
}

Quote.propTypes = {
  quote: PropTypes.arrayOf(PropTypes.string),
  loaded: PropTypes.bool,
  animationTime: PropTypes.number
}

export default Quote
