export const setFilters = filters => {
  return {
    type: "SET_FILTERS",
    filters
  }
}

export const portfolioLoaded = () => {
  return {
    type: "PORTFOLIO_LOADED"
  }
}

export const preloaderComplete = () => {
  return {
    type: "PRELOADER_COMPLETE"
  }
}

export const togglePreloader = () => {
  return {
    type: "HIDE_PRELOADER"
  }
}

export const hideForm = () => {
  return {
    type: "HIDE_CONTACT_FORM"
  }
}

export const setAboutMe = aboutMe => {
  return {
    type: "SET_ABOUT_ME",
    aboutMe
  }
}

export const applyFilter = filter => {
  return {
    type: "APPLY_FILTER",
    filter
  }
}

export const updatePosition = position => {
  return {
    type: "UPDATE_POSITION",
    position
  }
}

export const updateActivePortfolioItem = activeItem => {
  return {
    type: "UPDATE_ACTIVE_PORTFOLIO_ITEM",
    activeItem
  }
}

export const toggleSecondaryFilter = mode => {
  return {
    type: "TOGGLE_SECONDARY_FILTER",
    mode
  }
}

export const toggleNavigation = mode => {
  return {
    type: "TOGGLE_NAVIGATION",
    mode
  }
}
