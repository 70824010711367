import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import WayPoint from "react-waypoint"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//  components
import TitleTicker from "./TitleTicker"

//  hoc wrapper
import PortfolioFilterContainer from "../../containers/portfolio/PortfolioFilterContainer"

//  data
import { portfolio } from "../../data/data.json"

//  theme
import { colours } from "../../assets/themes/main"

//  styled-components
const Wrapper = styled.div`
  position: relative;
  font-size: 20px;

  scroll-snap-align: start;

  width: 100vw;
  min-height: max-content;
  height: 85vh;
  background: ${colours.cream};
  display: grid;
  grid-template-columns: 3fr 1fr;
`

const Block = styled.div`
  position: relative;
`

const Content = styled.div`
  position: absolute;
  bottom: 0;

  padding: 30px 45px;
`

const Right = styled(Content)``

const FilterItem = styled.div`
  cursor: pointer;
  /* color: lightgrey; */

  margin-top: 10px;

  transition: all 0.5s;

  text-decoration: ${props => (props.isActive ? "line-through" : "none")};
  transform: ${props => (props.isActive ? "skew(-10deg)" : "none")};

  & span {
    font-size: 18px;
    padding: 0 10px;
  }

  &:hover span {
    background-color: rgba(10, 10, 10, 0.1);
    transform: skewX(-10deg);
  }

  &:hover {
    transform: skewX(-10deg);
  }
`

const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 0%;
  left: 50%;
  margin: 10px;
  transform: translate(-50%, 0%);

  font-size: 40px;
  transition: all 0.2s ease-in-out;

  &:hover {
    font-size: 45px;
  }
`

const Heading = styled.div`
  margin-top: 10px;
  font-weight: 700;

  & hr {
    margin-left: 10px;

    height: 1px;
    border: none;
    background: black;
  }

  & span {
    font-size: 18px;
    padding: 0 10px;
  }
`

const PortfolioTitle = ({ filters, handleClick, activeFilter, toggle }) => {
  return (
    <WayPoint onEnter={() => toggle("hide")} onLeave={() => toggle("show")}>
      <div>
        <Wrapper>
          <Block>
            <TitleTicker ticker={portfolio.ticker} />
          </Block>

          <Block>
            <Right>
              {filters.map((el, i) => {
                const isActive = activeFilter === el ? true : false
                return (
                  <FilterItem key={i} name={el} isActive={isActive} onClick={handleClick}>
                    <span>{el}</span>
                  </FilterItem>
                )
              })}
              <Heading>
                <hr />
                <span>filter</span>
              </Heading>
            </Right>
          </Block>

          <Icon name="explore" onClick={handleClick}>
            <FontAwesomeIcon icon="chevron-circle-down" />
          </Icon>
        </Wrapper>
      </div>
    </WayPoint>
  )
}

PortfolioTitle.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  activeFilter: PropTypes.string,
  handleClick: PropTypes.func
}

export default PortfolioFilterContainer(PortfolioTitle)
