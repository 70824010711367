import React, { Component } from "react"

// data
import { navigation } from "../data/data.json"

//  components
import {
  NavItemRight,
  NavItemLeft,
  NavItemBottom,
  NavController
} from "../components/navigation/NavigationItems"

class Navigation extends Component {
  state = {}

  render() {
    const currentPage = this.props.location.pathname
    console.log(currentPage)
    return (
      <React.Fragment>
        <NavController />

        {currentPage === '/about' ? 
          <React.Fragment>
            <NavItemLeft currentPage={currentPage} {...navigation.portfolio} />
            <NavItemRight currentPage={currentPage} {...navigation.contact} />
            {/* <NavItemBottom currentPage={currentPage} {...navigation.bottom} /> */}
          </React.Fragment>
          : null
        }


        {currentPage === '/contact' ? 
          <React.Fragment>
            <NavItemLeft currentPage={currentPage} {...navigation.about} />
            <NavItemRight currentPage={currentPage} {...navigation.portfolio} />
            {/* <NavItemBottom currentPage={currentPage} {...navigation.bottom} /> */}
          </React.Fragment>
          : null
        }
        
        {currentPage !== '/about' && currentPage !== '/contact' ? 
          <React.Fragment>
            <NavItemLeft currentPage={currentPage} {...navigation.about} />
            <NavItemRight currentPage={currentPage} {...navigation.contact} />
            {/* <NavItemBottom currentPage={currentPage} {...navigation.bottom} /> */}
          </React.Fragment>
          : null
        }

      </React.Fragment>
    )
  }
}

export default Navigation
