import React, { Component } from "react"
import { connect } from "react-redux"

//  redux - actions
import { applyFilter, toggleSecondaryFilter } from "../../actions/actions"

const PortfolioFilterContainer = WrappedComponent => {
  class Wrapper extends Component {
    handleClick = ({ currentTarget: input }) => {
      const filter = input.getAttribute("name")
      if (filter !== "explore") {
        this.props.applyFilter(filter)
      }
      const scrollTo = 0.85 * window.innerHeight
      window.scrollTo(0, scrollTo)
    }

    toggleSecondaryFilter = mode => {
      if (mode === "show") {
        this.props.toggleSecondaryFilter(false)
      } else if (mode === "hide") {
        this.props.toggleSecondaryFilter(true)
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          toggle={this.toggleSecondaryFilter}
          handleClick={this.handleClick}
        />
      )
    }
  }

  const mapStateToProps = state => ({
    filters: state.filters,
    activeFilter: state.activeFilter,
    handleClick: state.handleClick,
    hide: state.hideSecondaryFilter
  })

  const mapDispatchToProps = dispatch => ({
    applyFilter: filter => {
      dispatch(applyFilter(filter))
    },
    toggleSecondaryFilter: mode => {
      dispatch(toggleSecondaryFilter(mode))
    }
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Wrapper)
}

export default PortfolioFilterContainer
