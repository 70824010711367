import React from "react"
import styled from "styled-components"

//  hoc wrapper
import ContactFormContainer from "../../containers/contact/ContactFormContainer"

//  styled-components
const Wrapper = styled.div`
  width: max-content;
`

const Section = styled.div`
  margin-bottom: 20px;
`

const SectionTitle = styled.p`
  /* margin-bottom: 10px; */
`

const Validation = styled.span`
  /* height: 20px; */
  color: red;
`

const Input = styled.input`
  background: none;
  padding-left: 5px;
  border: none;
  outline: none;
  font-size: 20px;

  width: 80%;
`
const Textarea = styled.textarea`
  background: none;
  border: none;
  outline: none;
  font-size: 16px;

  width: 80%;
`

const Content = styled.div`
  transition: all 1.6s;

  ${props =>
    props.hide
      ? `visibility: hidden;
        opacity: 0;
        transform: translate(0%, 0%);
        transition: all 0.5s;`
      : `visibility: visible;
        opacity: 1;
        transform: translate(0%, -10%); 
        transition: all 1.5s ease-in;
    `};
`

const Name = styled(Section)`
  margin-bottom: 60px;

  transition: all 0.5s;
  ${props =>
    props.error
      ? `transform: translate(0%, 447%); 
        transition: all 1.5s;`
      : `transform: translate(0%, 0%); 
        transition: all 0.5s ease-in;
    `};

  & > input {
    font-size: 40px;
    color: ${props => (props.error ? "black" : "#3c5870")};
  }
`

const Email = styled(Section)`
  & > input {
    color: ${props => (props.error ? "black" : "#3c5870")};
  }
`

const Message = styled(Section)`
  & > textarea {
    width: 80%;
    font-size: 20px;
    color: ${props => (props.error ? "black" : "#3c5870")};
  }
`

const Submit = styled(Section)`
  margin: 0px;

  & > button {
    padding: 10px 32px;
    border-radius: 4px;
    background: sandybrown;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
  }
`

const Form = ({ hideForm, form, errors, showErrors, handleChange, handleClick }) => {
  return (
    <Wrapper hide={hideForm}>
      <form autoComplete="off">
        <Name error={errors ? errors.name : null}>
          <SectionTitle>
            {showErrors && errors.name ? (
              <Validation>{errors ? errors.name : null}</Validation>
            ) : (
              <span>Lets start with a name...</span>
            )}
          </SectionTitle>
          <Input
            name="name"
            value={form.name}
            onChange={handleChange}
            type="text"
            autoFocus={true}
            autoComplete="off"
            placeholder="Type your name"
          />
        </Name>
        <Content hide={errors ? errors.name : false}>
          <Email error={errors ? errors.email : null}>
            <SectionTitle>
              {showErrors && errors.email ? (
                <Validation>{errors ? errors.email : null}</Validation>
              ) : (
                <span>and how can I reach you?</span>
              )}
            </SectionTitle>
            <Input
              name="email"
              value={form.email}
              onChange={handleChange}
              type="text"
              autoComplete="off"
              placeholder="Your email"
            />
          </Email>
          <Message error={errors ? errors.message : null}>
            <SectionTitle>
              {showErrors && errors.message ? (
                <Validation>{errors ? errors.message : null}</Validation>
              ) : (
                <span>Now, what's on your mind?</span>
              )}
            </SectionTitle>
            <Textarea
              name="message"
              value={form.message}
              onChange={handleChange}
              autoComplete="off"
              rows="7"
              warp="soft"
              placeholder="Share your thoughts"
            />
          </Message>
          <Submit>
            <button onClick={handleClick}>Done</button>
          </Submit>
        </Content>
      </form>
    </Wrapper>
  )
}

export default ContactFormContainer(Form)
