import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//  hoc wrapper
import ParallaxContainer from "../../containers/contact/ParallaxContainer"

//  styled components
const Wrapper = styled.div`
  font-size: 80px;
  font-weight: 800;
  width: max-content;

  color: #3C5870;

  ${props =>
    `transform: perspective(600px) rotateY(${props.offsetX}deg) rotateX(${props.offsetY}deg)`};
`

const LetsTalk = ({ message, offsetX, offsetY }) => {
  return (
    <Wrapper offsetX={offsetX} offsetY={offsetY}>
      {message}
    </Wrapper>
  )
}

LetsTalk.propTypes = {
  offsetX: PropTypes.string,
  offsetY: PropTypes.string,
  friction: PropTypes.number
}

export default ParallaxContainer(LetsTalk)
