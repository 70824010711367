import React from "react"
import styled from "styled-components"

//  hoc wrapper
import PortfolioFilterContainer from "../../containers/portfolio/PortfolioFilterContainer"

//  styled-components
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  margin: 20px;
  left: 50%;
  z-index: 8;
  transform: translate(-50%, 0);
  display: grid;
  grid-template-columns: ${props => `repeat(${props.n}, 1fr);`};

  ${props =>
    props.hide
      ? `visibility: hidden;
      opacity: 0;
      transition: all 0.2s;`
      : `visibility: visible;
      opacity: 1;
      transition: all 0.8s;`}
`

const FilterItem = styled.div`
  /* color: lightgrey; */

  margin-top: 10px;

  transition: all 0.5s;

  text-decoration: ${props => (props.isActive ? "line-through" : "none")};
  transform: ${props => (props.isActive ? "skew(-10deg)" : "none")};

  & span {
    cursor: pointer;
    font-size: 18px;
    padding: 0 10px;
    background: rgba(10, 10, 10, 0.7);
    color: white;
  }

  & span:hover {
    background: rgba(247, 243, 240, 0.6);
    color: black;
    transform: skewX(-10deg);
  }

  & span:hover {
    transform: skewX(-10deg);
  }
`

const FilterSecondary = ({ filters, handleClick, activeFilter, hide }) => {
  return (
    <Wrapper hide={hide} n={filters.length}>
      {filters.map((el, i) => {
        const isActive = activeFilter === el ? true : false
        return (
          <FilterItem key={i} isActive={isActive}>
            <span name={el} onClick={handleClick}>
              {el}
            </span>
          </FilterItem>
        )
      })}
    </Wrapper>
  )
}

export default PortfolioFilterContainer(FilterSecondary)
