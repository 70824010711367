import React, { Component } from 'react'
import {connect} from "react-redux"

const Wrapper = (WrappedComponent) => {
    class ProjectContainer extends Component {
        state = {  }

        componentDidMount = () => {
            window.scrollTo(0, 0)
          }

        render() { 
            return (<WrappedComponent {...this.props} />)
        }
    }

    const mapStateToProps = state => ({
        isNavShowing: !state.navigation.hide
    })
     
    return connect(mapStateToProps)(ProjectContainer)
}
 
export default Wrapper