import React from "react"
import styled from "styled-components"

//  containers
import PreloaderContainer from "../../containers/preloader/PreloaderContainer"

//  sub-components
import Quote from "./Quote"
import Explore from "./Explore"

//  theme
import { colours } from "../../assets/themes/main.json"

//  styled-components
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  background: ${colours.cream};

  transition: all 0.4s;
  transform: ${props => (props.hide ? "translate(0, -101vh)" : null)};
`

const Preloader = props => {
  const loaded = props.hasPreloaderCompleted && props.hasLoaded
  return (
    <Wrapper hide={props.hide}>
      <Quote quote={props.quote} animationTime={props.animationTime} loaded={loaded} />
      <Explore loaded={loaded} handleClick={props.handleClick} />
      {/* <Explore loaded={true} /> */}
    </Wrapper>
  )
}

export default PreloaderContainer(Preloader)
