import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//  hoc wrapper
import PortfolioNavContainer from "../../containers/portfolio/PortfolioNavContainer"

//  styled-components
const Wrapper = styled.div`
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -${props => props.position.percentage || 0}%);
  z-index: 1;

  width: 40%;

  visibility: ${props => (props.position.absolute > 245 ? "visible" : "hidden")};
  ${props =>
    props.position.absolute > 250
      ? `opacity: 1;
      transition: all 0.6s;`
      : `opacity: 0;
      transition: all 0.2s;`};
`

const PreNavItem = styled.div`
  color: white;
  overflow: hidden;
`

const Primary = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
`

const Navigation = styled.div``

const Icon = styled.div`
  font-size: 30px;
  position: relative;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const Title = styled.div`
  & span {
    font-size: 40px;
    font-weight: 700;
    cursor: pointer;
  }
`

const Secondary = styled.div`
  font-size: 20px;
  overflow: hidden;

  & > p {
    margin: 0px;
    text-transform: capitalize;
  }
`

const Line = styled.div`
  & hr {
    width: 70%;
    position: relative;
    top: 41%;
    margin-right: 15px;

    border: none;
    background: white;
    height: 2px;
  }
`

const NavItem = styled(PreNavItem)`
  margin: ${props => (props.isActive ? "75px 0px" : "0")};
  max-height: ${props => (props.isActive ? "110px" : "52px")};
  transition: all 0.5s;

  & ${Title} {
    opacity: ${props => (props.isActive ? "1" : "0.75")};
    transform: ${props => (props.isActive ? "skewX(-10deg)" : "none")};
    transition: all 0.3s;
  }

  & ${Title}:hover {
    opacity: 1;
    transform: skewX(-10deg);
  }

  & > ${Primary} {
    ${props => (props.isActive ? "cursor: pointer" : null)};
  }

  & > ${Secondary} {
    margin: ${props => (props.isActive ? "10px 0px" : "0px")};
    opacity: ${props => (props.isActive ? "1" : "0")};
    transition: all 0.3s;
  }

  & ${Navigation}, ${Line} {
    opacity: ${props => (props.isActive ? "0.7" : "0")};
    transition: all 0.5s;
  }

  &:hover ${Navigation}, &:hover ${Line} {
    opacity: ${props => (props.isActive ? "1" : "0")};
    transition: all 0.5s;
  }
`

const NavWidget = ({ portfolio, position, activeItem, history }) => {
  return (
    <Wrapper position={position}>
      {portfolio.map((el, i) => {
        const isActive = activeItem === el.project_name ? true : false
        return (
          <NavItem key={i} isActive={isActive}>
            <Primary onClick={() => history.push(`/project/${el.id}`)}>
              <Title>
                <span>{el.project_name}</span>
              </Title>

              <Line>
                <hr />
              </Line>

              <Navigation>
                <Icon>
                  <FontAwesomeIcon icon={"chevron-circle-right"} />
                </Icon>
              </Navigation>
            </Primary>

            <Secondary>
              <p>{el.labels.join(" | ")}</p>
              <p>{el.year}</p>
            </Secondary>
          </NavItem>
        )
      })}
    </Wrapper>
  )
}

NavWidget.propTypes = {
  portfolio: PropTypes.array,
  position: PropTypes.object,
  activeItem: PropTypes.string
}

export default PortfolioNavContainer(NavWidget)
