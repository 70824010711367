import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "react-router-dom"

//  styled-components
const NavLink = styled(Link)`
  text-decoration: none;
  color: black;
`

const Wrapper = styled.div`
  text-align: center;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  margin: 20px;
  z-index: 20;

  padding: 0px 10px;

  font-size: 30px;
  font-weight: 800;

  color: black;
  /*  background-color: rgba(255, 255, 255, 0.6); */

  transform: skewX(-10deg);

  transition: all 0.5s;

  &:hover {
    /* background-color: rgba(10, 10, 10, 0.6); */
  }

  &:hover ${NavLink} {
    /* color: white; */
  }
`

const SiteTitle = () => {
  return (
    <Wrapper>
      <span>
        <NavLink to="/">amaan</NavLink>
      </span>
    </Wrapper>
  )
}

SiteTitle.propTypes = {
  title: PropTypes.string
}

export default SiteTitle
