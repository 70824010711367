import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

//  container
import Container from '../containers/project/ProjectContainer'

//  theme
import theme from '../assets/themes/main'

//  portfolio data
import portfolio from '../data/portfolio'

//  styled components
const Wrapper = styled.div`
  padding-top: 70vh;
`

const CoverPhoto = styled.div`
  height: 70vh;
  width: 100vw;

  position: fixed;
  top: 0;
  z-index: -1;

  background: url(${props => props.photo});
  background-position: center;
  background-size: cover;
`

const Content = styled.div`
  background: ${theme.colours.cream};
  padding-top: 7px;
`

const Title = styled.div`
  display: grid;
  grid-template-columns: max-content 120px auto 60px max-content;

  & > div:nth-child(1) {
    font-size: 80px;
    font-weight: 600;
    letter-spacing: 3px;
    padding-left: 40px;
    padding-right: 20px;

    background: ${theme.colours.cream};
    border-radius: 2px;

    transform: translate(0, -50%);
  }

  & > div:nth-child(2) {
    padding-left: 20px;
    padding-right: 10px;
    margin-top: 12px;

    & hr {
      border: none;
      height: 1px;
      background: black;
    }
  }

  & > div:nth-child(3) {
    margin-top: 8px;
    font-size: 16px;

    & div {
      width: max-content;
      padding: 2px 10px;

      cursor: pointer;

      & a {
        color: black;
        text-decoration: none;
      }
      &:hover {
        transition: all 0.2s;
        background: lightgrey;
        transform: skew(-10deg);
      }
    }
  }

  & > div:nth-child(4) {
    margin-top: 12px;

    & hr {
      border: none;
      height: 1px;
      background: black;
    }
  }

  & > div:nth-child(5) {
    text-align: right;
    margin-top: 8px;
    margin-left: 5px;
    margin-right: 20px;

    & > div {
      padding: 2px 10px

    & a {
      color: black;
      text-decoration: none;
    }

    &:hover {
      transition: all 0.2s;
      background: lightgrey;
      transform: skew(-10deg);
    }
    }
  }
`

const Subtitle = styled.div`
  text-align: center;
  /* text-align: right; */

  margin-bottom: 70px;

  font-size: 40px;
  font-weight: 300;
  letter-spacing: 4px;
`

const Section = styled.div`
  width: 800px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);

  margin-bottom: 45px;

  display: grid;
  grid-template-columns: max-content auto 560px;

  & > div:nth-child(2) {
    padding: 0 40px;

    & hr {
      margin-top: 12px;
      height: 1px;
      border: none;
      background: black;
    }
  }
`

const SectionHeading = styled.div`
  text-transform: uppercase;

  font-size: 20px;
  font-weight: 300;
`

const SectionContent = styled.div`
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 1.7em;
`

const Details = styled.div`
  position: sticky;
  top: 70%;

  display: grid;
  grid-template-columns: max-content auto max-content;

  & > div:nth-child(1) > div {
    position: relative;
    transform: translate(-10px, 0) rotate(-90deg);
  }

  & > div:nth-child(3) > div {
    position: relative;
    transform: translate(10px, 0) rotate(90deg);
  }
`

const Photo = styled.div`
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);

  display: grid;
  grid-template-columns: 620px auto max-content;

  /* height: min-content; */
  width: 800px;
  height: 100%;
  margin-bottom: 50px;

  & > div:nth-child(1) {
    & img {
      width: 100%;
      height: 100%;
    }
  }

  & > div:nth-child(2) {
    padding: 0 30px;

    & hr {
      margin-top: 12px;
      height: 1px;
      border: none;
      background: black;
    }
  }

  & > div:nth-child(3) {
    font-size: 18px;
  }
`

const Project = props => {
  const id = props.match.params.project
  const project = portfolio[id]
  
  return (
    <Wrapper>
      <CoverPhoto
        photo={require('../assets/media/portfolio/' + project.meta.cover_photo)}
      />
      <Content>
        <Title>
          <div>{project.title}</div>
          <div>
            <hr />
          </div>
          <div>
            {!project.link ? (
              project.meta.year
            ) : (
              <div>
                <a
                  href={project.link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit {project.link.title}
                </a>
              </div>
            )}
          </div>
          <div>
            <hr />
          </div>
          <div>
            <div>
            <Link to="/">Back to Portfolio</Link>
            </div>
          </div>
        </Title>

        <Subtitle>{project.subtitle}</Subtitle>

        {project.description.map((section, i) => (
          <Section key={i}>
            <SectionHeading>{section.title}</SectionHeading>
            <div>
              <hr />
            </div>
            <SectionContent>{section.content}</SectionContent>
          </Section>
        ))}

        {/* {props.isNavShowing ? null : (
          <Details>
            <div>
              <div>somethign something</div>
            </div>
            <div />
            <div>
              <div>something something</div>
            </div>
          </Details>
        )} */}

        {project.photos.map((photo, i) => (
          <Photo key={i}>
            <div>
              <img
                src={require('../assets/media/portfolio/' + photo.link)}
                alt={photo.title}
              />
            </div>

            <div>
              <hr />
            </div>

            <div>{photo.title}</div>
          </Photo>
        ))}
      </Content>
    </Wrapper>
  )
}

export default Container(Project)
