import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

//  components
import SiteTitle from './components/SiteTitle'

//  pages
import Navigation from './pages/Navigation'
import AboutMe from './pages/AboutMe'
import Contact from './pages/Contact'
import Portfolio from './pages/Portfolio'
import Project from './pages/Project'

//  theme
import './assets/fonts/fontAwesome'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Route path="/" component={SiteTitle} />
          <Route path="/" component={Navigation} />
          <Switch>
            <Route path="/about" component={AboutMe} />
            <Route path="/contact" component={Contact} />
            <Route path="/project/:project" component={Project} />
            <Route path="/" component={Portfolio} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    )
  }
}

export default App
