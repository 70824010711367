import React, { Component } from "react"

const Wrapper = WrappedComponent => {
  class ParallaxContainer extends Component {
    state = {
      offsetX: "0",
      offsetY: "0",
      friction: this.props.friction
    }

    mouseMove = e => {
      let locationX = window.innerWidth / 2 - e.clientX
      let locationY = window.innerHeight / 2 - e.clientY

      let x = -locationX * this.state.friction
      let y = locationY * this.state.friction

      this.setState({
        offsetX: x.toFixed(1),
        offsetY: y.toFixed(1)
      })
    }

    componentDidMount = () => {
      document.addEventListener("mousemove", this.mouseMove)
    }

    componentWillUnmount = () => {
      document.removeEventListener("mousemove", this.mouseMove)
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />
    }
  }

  return ParallaxContainer
}

export default Wrapper
