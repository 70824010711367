import React from 'react'
import styled from 'styled-components'

//  data
import { aboutMe } from '../data/data.json'

// theme
import theme from '../assets/themes/main'

//  components
import TripleTicker from '../components/about_me/TripleTicker'

//  styled-components
const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  /* color: #2E3E48; */
  color: #3C5870;
`

const Story = styled.div`
  max-width: 60vw;
  text-align: center;

  position: relative;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);

  border-radius: 3px;
  background: ${theme.colours.cream};
`

const Title = styled.div`
  margin-bottom: 50px;

  & hr {
    height: 5px;
    width: 260px;
    border: none;
    margin-top: 5px;
    margin-bottom: 15px;
    background: sandybrown;
  }
`

const Name = styled.div`
  font-size: 45px;
  font-weight: 600;
`

const P = styled.p`
  font-size: 17px;
  line-height: 22px;
  /* letter-spacing: 1px; */
`

const AboutMe = props => {
  return (
    <Wrapper>
      <Story>
        <Title>
          <Name>amaan saeed</Name>
          <hr />
          <TripleTicker ticker={aboutMe.ticker} />
        </Title>
        {aboutMe.story.map((paragraph, i) => (
          <P key={i}>{paragraph}</P>
        ))}
      </Story>
    </Wrapper>
  )
}

export default AboutMe
